<template>
    <el-card class="box-card" id="billing">
        <el-row slot="header" class="clearfix" type="flex" justify="space-between">
            <el-col :span="12" class="text-align-left">
                <span>{{ $t('sells.order.header-8') }}</span>
            </el-col>
            <el-col :span="12" class="text-align-right">
                <template v-if="hasCloudBillingProviders">
                    <el-select size="mini" v-model="selectedProvider" class="mr-3" :disabled="isSaving">
                        <el-option label="Standard" :value="0"></el-option>
                        <el-option
                            v-for="provider in billingProvidersAccounts"
                            :label="provider.text"
                            :value="provider.value"
                        ></el-option>
                    </el-select>
                </template>
                <el-button
                    v-if="selectedProvider > 0"
                    type="primary"
                    icon="el-icon-upload"
                    size="mini"
                    :disabled="dialogInsert || isSaving"
                    :loading="isSaving"
                    @click="generateCloudInvoice"
                    >Genereaza factura</el-button
                >
                <el-button
                    v-else
                    type="primary"
                    icon="el-icon-upload"
                    size="mini"
                    :disabled="dialogInsert"
                    @click="openAddInvoiceDialog"
                    >Incarca factura</el-button
                >
            </el-col>
        </el-row>

        <el-row>
            <template v-if="invoices != null && invoices.length > 0">
                <el-table :data="invoices" style="width: 100%" size="small">
                    <el-table-column label="Serviciu">
                        <template slot-scope="scope">
                            <el-tag :type="getProvider(scope.row).tag">{{ getProvider(scope.row).name }}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="series" label="Serie"> </el-table-column>
                    <el-table-column prop="number" label="Numar"> </el-table-column>
                    <el-table-column prop="invoiceDate" label="Data"></el-table-column>

                    <el-table-column prop="" :label="$t('general.actions')" width="120px">
                        <template slot-scope="scope">
                            <el-button
                                :loading="downloadLoading[`${scope.row.series}-${scope.row.number}`]"
                                :disabled="deleteLoading[`${scope.row.series}-${scope.row.number}`]"
                                type="success"
                                plain
                                size="mini"
                                icon="el-icon-download"
                                v-if="hasFileAttached(scope.row)"
                                @click="downloadInvoice(scope.row.series, scope.row.number)"
                            ></el-button>
                            <el-button
                                :loading="deleteLoading[`${scope.row.series}-${scope.row.number}`]"
                                :disabled="downloadLoading[`${scope.row.series}-${scope.row.number}`]"
                                type="danger"
                                plain
                                size="mini"
                                icon="el-icon-delete"
                                @click="deleteInvoice(scope.row.series, scope.row.number)"
                            ></el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </template>
            <template v-else>
                <el-alert
                    title="Nu exista facturi inregistrate pentru aceasta comanda."
                    type="warning"
                    :closable="false"
                >
                </el-alert>
            </template>
        </el-row>
        <AddInvoiceDialog
            :order="order"
            :billingProvidersAccounts.sync="billingProvidersAccounts"
            v-if="dialogInsert"
        />
    </el-card>
</template>

<script>
import { sync, call, get } from 'vuex-pathify';
import Vue from 'vue';
import { EventBus } from '@/utils/Bus.js';

import { createBlob } from '@/utils/index';
const AddInvoiceDialog = () => import('./AddInvoiceDialog');

export default {
    props: ['order', 'billingProviders'],
    components: {
        AddInvoiceDialog,
    },
    data() {
        return {
            providers: [
                {
                    name: 'Standard',
                    tag: 'info',
                    value: 0,
                },
                {
                    name: 'Smartbill',
                    tag: 'success',
                    value: 1,
                },
                {
                    name: 'FGO',
                    tag: 'warning',
                    value: 2,
                },
                {
                    name: 'Oblio',
                    tag: 'danger',
                    value: 3,
                },
                {
                    name: 'Facturis',
                    tag: 'primary',
                    value: 4,
                },
            ],
            deleteLoading: {},
            downloadLoading: {},
            selectedProvider: 0,
            isSaving: false,
        };
    },
    methods: {
        getOrderInvoices: call('sales/GetOrderInvoices'),
        deleteOrderInvoice: call('sales/DeleteOrderInvoice'),
        downloadOrderInvoice: call('sales/DownloadOrderInvoice'),
        addOrderInvoice: call('sales/AddOrderInvoice'),
        openAddInvoiceDialog() {
            this.dialogInsert = true;
        },
        async generateCloudInvoice() {
            if (this.selectedProvider < 1) {
                return this.$store.dispatch('snackbar/addMessage', {
                    type: 'error',
                    message: `Selectati un furnizor de servicii de facturare pentru a putea emite factura!`,
                });
            }
            const chosenProvider = this.billingProvidersAccounts.find((p) => p.value === this.selectedProvider);
            const confirm = await this.$confirm(
                `Sigur emiteti factura pentru comanda ${this.order.orderId} prin ${chosenProvider.text}?`,
                'Atentie',
                {
                    confirmButtonText: 'Da',
                    cancelButtonText: 'Nu',
                    type: 'warning',
                }
            ).catch(() => false);
            if (!confirm) {
                return;
            }
            try {
                this.isSaving = true;
                const formData = new FormData();

                formData.append('oid', this.order.orderId);
                formData.append('providerId', this.selectedProvider);
                await this.addOrderInvoice(formData);
                this.$store.dispatch('snackbar/addMessage', {
                    type: 'success',
                    message: `Factura a fost emisa cu succes.`,
                });
                EventBus.$emit('invoiceEvent');
                this.closeDialog();
            } catch (e) {
                console.log('e', e);
                this.$reportToSentry(e, {
                    extra: {
                        fn: 'saveInvoice',
                    },
                });
            } finally {
                this.isSaving = false;
            }
        },
        async deleteInvoice(series, number) {
            const confirm = await this.$confirm(
                `Sigur stergeti factura ${series} ${number}?. Pentru facturile emise prin servicii ca Smartbill, Oblio, etc., acestea nu vor fi sterge din platforma respectiva, doar din contul marketplace!`,
                'Atentie',
                {
                    confirmButtonText: 'Da',
                    cancelButtonText: 'Nu',
                    type: 'warning',
                }
            ).catch(() => false);
            if (!confirm) {
                return;
            }
            try {
                Vue.set(this.deleteLoading, `${series}-${number}`, true);

                await this.deleteOrderInvoice({
                    oid: this.order.orderId,
                    series,
                    number,
                });

                this.$store.dispatch('snackbar/addMessage', {
                    type: 'success',
                    message: `Factura a fost stearsa cu succes.`,
                });

                EventBus.$emit('invoiceEvent');

                return this.getOrderInvoices({ orders_id: this.order.orderId });
            } catch (e) {
                this.$reportToSentry(e, {
                    extra: {
                        fn: 'deleteInvoice',
                        params: {
                            oid: this.order.orderId,
                            series,
                            number,
                        },
                    },
                });
            } finally {
                Vue.set(this.deleteLoading, `${series}-${number}`, false);
            }
        },
        async downloadInvoice(series, number) {
            try {
                Vue.set(this.downloadLoading, `${series}-${number}`, true);
                const invoice = await this.downloadOrderInvoice({
                    oid: this.order.orderId,
                    series,
                    number,
                });

                const blobUrl = createBlob(invoice.type, invoice.data);
                const dlnk = document.createElement('a');
                dlnk.href = blobUrl;
                dlnk.setAttribute('target', '_blank');
                dlnk.download = 'factura-' + series + '-' + number + '.' + invoice.extension;
                document.body.appendChild(dlnk);
                dlnk.click();
            } catch (e) {
                this.$reportToSentry(e, {
                    extra: {
                        fn: 'downloadInvoice',
                        params: {
                            oid: this.order.orderId,
                            series,
                            number,
                        },
                    },
                });
            } finally {
                Vue.set(this.downloadLoading, `${series}-${number}`, false);
            }
        },
        getProvider(invoice) {
            return this.providers.find((p) => p.value === invoice.providerId);
        },
        hasFileAttached(invoice) {
            return invoice.hasFileAttached == 1;
        },
    },
    computed: {
        invoices: get('sales/order@invoices.list'),
        dialogInsert: sync('sales/order@invoices.dialogInsert'),
        billingProvidersAccounts() {
            return this.billingProviders;
        },
        hasCloudBillingProviders() {
            return (
                this.billingProvidersAccounts &&
                Array.isArray(this.billingProvidersAccounts) &&
                this.billingProvidersAccounts.length > 0
            );
        },
    },
    watch: {
        dialogInsert(val) {
            if (val === false) {
                return this.getOrderInvoices({ orders_id: this.order.orderId });
            }
        },
        billingProvidersAccounts(val) {
            if (val.length > 0) {
                this.selectedProvider = val[0].value;
            }
        },
        selectedProvider(val) {
            if (val >= 0) {
                EventBus.$emit('billingProviderChanged', val);
            }
        },
    },
    mounted() {
        if (this.hasCloudBillingProviders) {
            this.selectedProvider = this.billingProvidersAccounts[0].value;
        }
        return this.getOrderInvoices({
            orders_id: parseInt(this.order.orderId),
        }).catch((e) => {
            this.$reportToSentry(e, {
                extra: {
                    fn: 'getOrderInvoices',
                    params: {
                        orders_id: parseInt(this.order.orderId),
                    },
                },
            });
        });
    },
    beforeDestroy() {},
};
</script>

<style scoped>
::v-deep div.el-card__header {
    /* padding: 10px !important; */
}
</style>
